import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';
import { node } from 'prop-types';
import useSWR, { useSWRConfig } from 'swr';
import noop from 'lodash/noop';
import { dequal } from 'dequal';
import { useTranslation } from 'react-i18next';

import { API_LOGIN, API_USER_PROFILE, PUBLIC_ROUTES } from 'Constants';
import fetch from 'Utils/fetch';
import useFetch from 'Hooks/useFetch';
import useRouter from 'Hooks/useRouter';
import UserPropType from 'Types/user';
import { setUserForTracking } from 'Utils/analytics';

const defaultData = {
  loggedIn: false,
  sessionExpired: false,
  email: null,
  language: 'ro',
  defaultCurrency: 'RON',
  checkout: {},
  cart: {},
  accountManager: {}
};

export const UserContext = createContext({
  data: defaultData,
  error: undefined,
  isLoading: false,
  mutate: noop,
  userLogin: noop,
  updateUser: noop,
  userLogout: noop,
  getUserData: noop,
  setUserData: noop
});

const getLocalState = email => typeof localStorage !== 'undefined' ? (JSON.parse(localStorage.getItem(email) || '{}') || {}) : {};

const UserProvider = memo(({ children, user: initialUser }) => {
  const fetcher = useFetch();
  const { cache } = useSWRConfig();
  const { location, push } = useRouter();
  const initialUserData = initialUser || cache.get('user') || {};
  const { i18n: { language, changeLanguage } } = useTranslation();
  if (
    initialUserData?.userId
    && !('loggedIn' in initialUserData)
  ) {
    initialUserData.loggedIn = true;
  }
  const localUserData = getLocalState(initialUserData?.email);
  const [user, setUser] = useState({
    ...defaultData, // default login state mandatory !
    ...(localUserData || {}),
    ...(initialUserData || {})
  });
  const [loading, setLoading] = useState(false);
  const isPagePublic = PUBLIC_ROUTES.find(item => item === location.pathname);

  // TODO: remove when going public !!
  if (
    !Object.keys(initialUserData).length
    && !user?.email
    && location.pathname !== '/login'
    && !isPagePublic
  ) {
    push('/login', {
      search: location.search,
      pathname: location.pathname,
      hash: location.hash,
      ...location.state
    });
  }

  const response = useSWR(
    user?.email ? `${API_USER_PROFILE}?uid=${user?.email}` : null,
    async path => {
      if (loading) return null;
      setLoading(true);
      try {
        const data = await fetcher(path);
        if (data) {
          setLoading(false);
        }
        return {
          ...data,
          loggedIn: true,
          sessionExpired: false
        };
      } catch {
        // nothing
      } finally {
        setLoading(false);
      }
      return null;
    },
    {
      revalidateOnMount: true
    }
  );

  useEffect(() => {
    if (response?.data?.email) {
      // todo: here we assume username is actually email address
      const mergedUser = {
        ...user,
        ...(getLocalState(response?.data?.email) || {}),
        ...response.data
      };
      setUserData(mergedUser);
    }
  }, [
    response?.data?.userId,
    response?.data?.loggedIn
  ]);

  useEffect(() => {
    if (user?.userId) {
      setUserForTracking(user?.userId, user?.isImpersonated);
    }
  }, [user?.userId, user?.isImpersonated]);

  // NOTE: it is mandatory that the login method has to use the non-context wrapped fetch !!
  const userLogin = useCallback(
    async data => {
      const r = await fetch(API_LOGIN, {
        method: 'POST',
        body: JSON.stringify(data)
      });

      if (r?.success) {
        // trigger account re-fetch
        if (!response.data) {
          setUser(prevUser => ({
            ...prevUser,
            loggedIn: true,
            sessionExpired: false,
            email: data.username
          }));
        }
        response.mutate({
          ...user,
          loggedIn: true,
          sessionExpired: false,
          email: data.username
        }, true);
      }
      return r;
    },
    [user]
  );

  const userLogout = event => {
    event.preventDefault();
    const $formEl = event.target.closest('form');
    // TODO: uncomment after api logout method supported
    // return fetcher(API_LOGOUT, {
    //   method: 'POST',
    //   body: JSON.stringify({ userId: user?.userId })
    // }).then(r => {
    //   if (r?.success && user) {
    //     setUserData({
    //       loggedIn: false,
    //       sessionExpired: false,
    //     });
    //   }
    //   return r;
    // });
    response.mutate({
      ...user,
      loggedIn: false,
      sessionExpired: false
    }, false);
    $formEl?.submit();
    // push('/login');
  };

  /**
   * update user
   * @param {object} payload
   * @returns {Promise<*|null>}
   */
  const updateUser = async payload => {
    try {
      setLoading(true);
      const d = await fetcher(API_USER_PROFILE, {
        method: 'PUT',
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: JSON.stringify(payload)
      });
      setUserData(payload);
      return d;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Get user data
   * @param {string?} prop
   * @returns {*}
   */
  const getUserData = useCallback(
    prop => {
      if (prop && user) {
        if (prop in user) {
          return user[prop];
        }

        // console.warn(`Prop: ${ prop } does not exist in localState for user ${ user.email }.`);
        return false;
      }

      return user;
    },
    [user]
  );

  const setUserData = data => {
    setUser(prevState => {
      const newState = {
        ...prevState,
        ...data
      };
      if (!dequal(newState, prevState)) {
        return newState;
      }
      return prevState;
    });
  };

  useEffect(() => {
    if (user?.email) {
      localStorage.setItem(user.email, JSON.stringify(user));
    }
    if (
      user?.language
      && user.language !== language
    ) {
      console.log('change language', user.language);
      changeLanguage(user.language);
    }
  }, [user]);

  useEffect(() => {
    if (
      location?.state?.status === 401
      // || location?.state?.status === 500
    ) {
      // TODO: remove on public version
      if (
        !user?.email
        && !user?.loggedIn
        && !isPagePublic
      ) {
        push('/login', {
          search: location.search,
          pathname: location.pathname,
          hash: location.hash,
          ...location.state
        });
      }
      response.mutate({
        ...user,
        loggedIn: false,
        sessionExpired: true
      }, false);
    }
  }, [location?.state]);

  const memoizedUser = useMemo(
    () => ({
      data: user,
      mutate: response.mutate,
      error: response.error,
      isLoading: loading,
      getUserData,
      setUserData,
      userLogin,
      userLogout,
      updateUser
    }),
    [user, loading, userLogin, getUserData, setUserData, response.error, response.mutate]
  );

  return (
    <UserContext.Provider value={ memoizedUser }>
      { children }
    </UserContext.Provider>
  );
});

UserProvider.propTypes = {
  children: node,
  user: UserPropType
};

UserProvider.displayName = 'UserProvider';

export default UserProvider;
